<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
    <v-card class="card">
      <v-card-title class="heading justify-center">Add Book</v-card-title>
      <v-card-text>
        <v-row class="px-5 pt-4">
          <v-col cols="12" md="3" sm="3">
            <v-text-field v-model="accno" outlined label="Accession No"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete v-model="selectedseries" outlined label="Series" :items="serieslist" item-value="seriesId"
              item-text="seriesName"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete v-model="selecteddepartment" outlined label="Department" :items="departmentlist"
              item-text="name" item-value="id"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <v-autocomplete v-model="selectedpublisher" outlined label="Publisher D" :items="publisherlist"
              item-value="publisherId" item-text="publisher"></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="pb-3" justify="center" align="center">
          <v-btn class="m-2" color="info" @click="searchByA_no">Search</v-btn>
        </v-row>

        <v-data-table :headers="headers" :items="bookList" class="elevation-1 nowrap" :search="search">

          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details>
              </v-text-field>
              <span style="width: 20px"></span>
              <v-btn class="mb-2 button" @click="addRack">Add Book</v-btn>
              <v-dialog v-model="dialog" max-width="1200px">
                <v-card>
                  <v-form v-model="valid" ref="form">
                    <v-card-title class="heading">
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12" lg="12" v-if="isImg">
                            <center>
                              <v-img height="150" width="150" :src="img"></v-img>
                            </center>
                          </v-col>
                          <v-col v-if="!inSave" cols="12" sm="12" md="4" lg="4">
                            <label>Book Series</label>
                            <v-autocomplete dense outlined :items="bookSeriesList" item-value="seriesId"
                              item-text="seriesName" v-model="editedItem.seriesId" persistent-hint></v-autocomplete>
                          </v-col>
                          <v-col v-else cols="12" sm="12" md="4" lg="4">
                            <label>Book Series</label>
                            <v-autocomplete dense outlined :items="bookSeriesList" item-value="seriesId"
                              item-text="seriesName" v-model="editedItem.seriesId" @change="getavalableacc()"
                              persistent-hint></v-autocomplete>
                          </v-col>
                          <!-- :disabled="!inSave" -->
                          <v-col v-if="editedItem.acc && inSave" cols="12" sm="12" md="4" lg="4">
                            <label>Accesation NO</label><span style="color: red; font-weight: bolder">*</span>
                            <v-text-field dense outlined v-model="editedItem.acc" item-text="roleLink_list_id"
                              item-value="id" persistent-hint readonly disabled :error="errorMsg.acc ? true : false"
                              :error-messages="errorMsg.acc ? 'Required' : ''" :rules="[(v) => !!v || 'required']"
                              @blur="fetchBookInfo()"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>ISBN</label><span style="color: red; font-weight: bolder">*</span>
                            <v-text-field dense outlined v-model="editedItem.isbn" item-text="roleLink_list_id"
                              item-value="id" persistent-hint :error="errorMsg.isbn ? true : false"
                              :error-messages="errorMsg.isbn ? 'Required' : ''" :rules="[(v) => !!v || 'required']"
                              @blur="fetchBookInfo()"></v-text-field>
                          </v-col>
                          <v-col style="padding-top:0px;" cols="6" sm="12" md="4" lg="4">
                            <label>Number of Copies</label><span style="color: red; font-weight: bolder">*</span>
                            <v-text-field dense outlined v-model="editedItem.copies" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number" :readonly="!inSave" :disabled="!inSave"
                              :error="errorMsg.copies ? true : false" :error-messages="errorMsg.copies ? 'Required' : ''
                                " :rules="[(v) => !!v || 'required']"></v-text-field>
                          </v-col>
                          <v-col v-if="inSave" cols="12" sm="12" md="4" lg="4">
                            <label v-if="inSave">Date of Entry</label>
                            <v-menu v-if="inSave" v-model="showPicker6" :close-on-content-click="false"
                              transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="editedItem.date_of_entry" placeholder="Select Date"
                                  hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="editedItem.date_of_entry" no-title
                                @input="showPicker6 = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Language</label>
                            <v-autocomplete dense outlined :items="languageList" item-value="languageId"
                              item-text="language" v-model="editedItem.languageId" persistent-hint></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Title</label>

                            <v-combobox style="padding: 0px; margin: 0px" v-model="selectedtitle1" :items="allBookInfo"
                              item-text="displayname" outlined hide-no-data hide-details dense
                              @input.native="onload1($event.srcElement.value)" @change="settext()"></v-combobox>
                          </v-col>

                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Description</label>
                            <v-textarea dense outlined rows="2" v-model="editedItem.description"
                              item-text="roleLink_list_id" item-value="id" persistent-hint></v-textarea>
                          </v-col>

                          <v-col cols="12" sm="12" md="4" lg="4" v-if="!book_authors">
                            <label>Author(s)</label>
                            <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                            <v-autocomplete dense outlined :items="authorList" item-value="authorId" item-text="author"
                              v-model="editedItem.authors" persistent-hint multiple></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" v-else>
                            <label>Author(s)</label>
                            <v-icon color="blue" class="mr-2" @click="authordialog = true">mdi-plus-box</v-icon>
                            <v-text-field dense outlined v-model="editedItem.authors"></v-text-field>
                          </v-col>

                          <v-dialog v-model="authordialog" width="500">
                            <v-card>
                              <v-card-title class="grey lighten-2">
                                Add Author
                              </v-card-title>

                              <v-card-text style="padding-bottom: 0px">
                                <label class="add-text">Author</label><span
                                  style="color: red; font-weight: bolder">*</span>
                                <v-text-field class="text" v-model="authorName" outlined dense clearable
                                  required></v-text-field>

                                <label class="add-text">Description</label>
                                <v-textarea class="text" v-model="author_description" outlined dense
                                  rows="2"></v-textarea>
                              </v-card-text>

                              <div class="text-center">
                                <v-spacer></v-spacer>
                                <v-btn class="m-3" color="error" @click="authordialog = false">
                                  Close
                                </v-btn>
                                <v-btn class="m-3" color="success" @click="addAuthor()">Add</v-btn>
                              </div>
                            </v-card>
                          </v-dialog>
                          <v-col cols="12" sm="12" md="4" lg="4" v-if="!publisherText">


                            <label>Publisher</label>
                            <v-icon color="blue" class="mr-2" @click="publisher_dialog = true">mdi-plus-box</v-icon>
                            <v-autocomplete dense outlined :items="publisherList" item-value="publisherId"
                              item-text="publisher" v-model="editedItem.publisherId" persistent-hint></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" v-else>
                            <label>Publisher</label>
                            <v-icon color="blue" class="mr-2" @click="publisher_dialog = true">mdi-plus-box</v-icon>
                            <v-text-field dense outlined v-model="editedItem.publisherId" persistent-hint></v-text-field>
                          </v-col>
                          <v-dialog v-model="publisher_dialog" width="500">
                            <v-card>
                              <v-card-title class="grey lighten-2">
                                Add Publisher
                              </v-card-title>

                              <v-card-text style="padding-bottom: 0px">
                                <label class="add-text">Publisher</label><span
                                  style="color: red; font-weight: bolder">*</span>
                                <v-text-field class="text" v-model="publishername" outlined dense clearable
                                  required></v-text-field>

                                <label class="add-text">Short Name</label><span
                                  style="color: red; font-weight: bolder">*</span>
                                <v-text-field class="text" v-model="publisher_short_name" outlined dense
                                  rows="2"></v-text-field>
                              </v-card-text>

                              <div class="text-center">
                                <v-spacer></v-spacer>
                                <v-btn class="m-3" color="error" @click="publisher_dialog = false">
                                  Close
                                </v-btn>
                                <v-btn class="m-3" color="success" @click="addpubliasher()">Add</v-btn>
                              </div>
                            </v-card>
                          </v-dialog>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Publication Place</label>
                            <v-text-field dense outlined v-model="editedItem.place" item-text="roleLink_list_id"
                              item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <!---pub year-->
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Publication Year</label>
                            <v-menu v-model="showPickerYP" :close-on-content-click="false" transition="scale-transition"
                              offset-y full-width max-width="290px" min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="editedItem.publicationYear" placeholder="Select Year" hint="YYYY"
                                  persistent-hint dense outlined v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="editedItem.publicationYear" no-title type="year"
                                @input="showPickerYP = false"></v-date-picker>
                              </v-date-picker>
                            </v-menu>

                          </v-col>
                          <!--year end -->
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Volume</label>
                            <v-text-field dense outlined v-model="editedItem.volume" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Edition</label>
                            <v-text-field dense outlined v-model="editedItem.edition" item-text="roleLink_list_id"
                              item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Edition Year</label>
                            <v-text-field dense outlined v-model="editedItem.year" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Number of Pages</label>
                            <v-text-field dense outlined type="number" v-model="editedItem.pages"
                              item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Classification/Call No</label>
                            <v-text-field dense outlined v-model="editedItem.cno" item-text="roleLink_list_id"
                              item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Type</label>
                            <v-autocomplete dense outlined :items="bookTypeList" item-value="bookTypeId"
                              item-text="bookType" v-model="editedItem.bookTypeId" persistent-hint></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Subject</label>
                            <v-text-field dense outlined v-model="editedItem.subject" item-text="roleLink_list_id"
                              item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Department</label>
                            <v-autocomplete class="text" item-text="name" item-value="id" :items="libdeptlist"
                              v-model="editedItem.libdept" dense outlined></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Source/vendor</label>
                            <v-text-field dense outlined v-model="editedItem.vendor" item-text="vendor"
                              item-value="vendor" persistent-hint></v-text-field>
                          </v-col>


                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label v-if="!editedItem.isDonated">Bill Number</label>
                            <v-text-field v-if="!editedItem.isDonated" dense outlined v-model="editedItem.billNo"
                              item-text="roleLink_list_id" item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4" v-if="!editedItem.isDonated">
                            <label>Date of Purchase</label>
                            <v-menu v-model="showPicker1" :close-on-content-click="false" transition="scale-transition"
                              offset-y full-width max-width="290px" min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="editedItem.purchaseDate" placeholder="Select Date"
                                  hint="YYYY/MM/DD" persistent-hint dense readonly outlined v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="editedItem.purchaseDate" no-title
                                @input="showPicker1 = false"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Purchase price</label>
                            <v-text-field dense outlined v-model="editedItem.price" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number" @input="cal_discount()"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Discount</label>
                            <v-text-field dense outlined v-model="editedItem.discount" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number" @input="cal_discount()"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book price</label>
                            <v-text-field dense outlined v-model="editedItem.bookprice" item-text="roleLink_list_id"
                              item-value="id" persistent-hint type="number"></v-text-field>
                          </v-col>
                          <!-- <v-col cols="6" sm="12" md="4" lg="4">
                              <label>Discount Percentage</label>
                              <v-text-field
                                dense
                                outlined
                                v-model="editedItem.discountPrice"
                                item-text="roleLink_list_id"
                                item-value="id"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col> -->
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Keywords</label>
                            <v-text-field dense outlined v-model="editedItem.keywords" item-text="roleLink_list_id"
                              item-value="id" persistent-hint></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Binding Type</label>
                            <v-autocomplete dense outlined :items="bookFormatList" item-value="id" item-text="name"
                              v-model="editedItem.bookFormat" persistent-hint></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Program</label>
                            <v-autocomplete class="text" item-text="name" item-value="id" :items="program_list"
                              v-model="editedItem.program" dense outlined></v-autocomplete>
                          </v-col>

                          <!-- <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Date of Publication</label>
                            <v-menu
                              v-model="showPicker"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="editedItem.publicationDate"
                                  placeholder="Select Date"
                                  hint="YYYY/MM/DD"
                                  persistent-hint
                                  dense
                                  readonly
                                  outlined
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.publicationDate"
                                no-title
                                @input="showPicker = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col> -->


                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Book Category</label>
                            <v-autocomplete dense outlined :items="bookCatList" item-value="bookCatId" item-text="bookCat"
                              v-model="editedItem.bookCatId" persistent-hint></v-autocomplete>
                          </v-col>


                          <v-col cols="12" sm="12" md="4" lg="4">
                            <label>Library</label><span style="color: red; font-weight: bolder">*</span>
                            <!--  <v-text-field
                              v-model="editedItem.library"
                              
                              filled
                              readonly
                            ></v-text-field>-->
                            <v-autocomplete dense outlined :items="libraryList" item-value="id" item-text="name"
                              v-model="editedItem.library" persistent-hint :error="errorMsg.library ? true : false"
                              :error-messages="errorMsg.library ? 'Required' : ''
                                " :rules="[(v) => !!v || 'required']"></v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="12" md="4" lg="4">
                            <v-checkbox style="padding-top: 30px" v-model="editedItem.isDonated"
                              label="Is Received From Donation?" color="black" dense hide-details></v-checkbox>
                          </v-col>


                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                      <v-btn class="button" @click="save">Save</v-btn>
                    </v-card-actions>
                    <!-- :disabled="!valid" -->
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.copies="{ item }">
            <span>
              {{ item.copies }}
            </span>

            <v-icon @click="editcopy(item)">mdi mdi-pencil-box</v-icon>

          </template>
          <template v-slot:item.rackInfo="{ item }">
            <span v-if="item.mapLocation == 0">
              <i class="mdi mdi-check check"></i></span>
            <span v-else> <i class="mdi mdi-close cross"></i></span>
            <router-link :to="{ name: 'add-location', params: { bookId: item.id } }" style="text-decoration: none">
              <v-btn small color="info">Add Location</v-btn>
            </router-link>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
            </div>
          </template>
          <template v-slot:item.bookItem="{ item }">
            <router-link :to="{ name: 'book-item', params: { bookId: item.id } }" style="text-decoration: none">
              <v-btn small color="info">Book Item</v-btn>
            </router-link>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template>
      <div class="text-center">
        <v-dialog v-model="newBook" width="800">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              Newly added book copies data
            </v-card-title>

            <v-card-text>
              <v-simple-table v-if="newbook_data.length > 0" class="mt-3" style="border: 1px solid">
                <template v-slot:default>
                  <thead>
                    <tr style="background: lightgray">
                      <th class="text-left" style="font-size: 16px">
                        Book Title
                      </th>
                      <th class="text-left" style="font-size: 16px">
                        Accession Number
                      </th>
                      <th class="text-left" style="font-size: 16px">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item1 in newbook_data" :key="item1.acc_no">
                      <td>{{ item1.title }}</td>
                      <td>{{ item1.acc_no }}</td>
                      <td>{{ item1.status }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" @click="newBook = false"> OK </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </div>
</template>
   </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard

export default {
  data: () => ({
    publisher_dialog: false,
    publisher_short_name: "",
    publishername: "",
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Add Book periodicals',
        disabled: false,
        href: '/add_book_periodicals',
      },
      {
        text: 'Add Book',
        disabled: true,
        href: '',
      },
    ],
    showPickerYP: false,
    showPicker: false,
    showPicker1: false,
    showPicker5: false,
    showPicker6: false,
    bookFormatList: [],
    bookTypeList: [],
    BookCatList: [],
    bookSeriesList: [],
    accno: "",
    languageList: [],
    publisherList: [],
    libraryList: "",
    bookList: [],
    authorList: [],
    valid: false,
    overlay: false,
    errorMsg: {
      roleLinkId: false,
    },
    snackbar_msg: "",
    color: "",
    menu1: false,
    menu2: false,
    snackbar: false,
    search: "",
    org: "",
    inSave: true,
    newBook: false,
    program_list: null,
    program: null,
    dialog: false,
    authordialog: false,
    authorName: "",
    author_description: "",
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      { text: "Actions", value: "action", sortable: false },
      { text: "Book Item(s)", value: "bookItem", sortable: false },
      {
        text: "ISBN",
        align: "left",
        sortable: true,
        value: "isbn",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Edition",
        align: "left",
        sortable: true,
        value: "edition",
      },
      {
        text: "date_of_entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Binding Type",
        align: "left",
        sortable: true,
        value: "bookFormat",
      },
      {
        text: "Book Type",
        align: "left",
        sortable: true,
        value: "bookType",
      },
      {
        text: "Program",
        align: "left",
        sortable: true,
        value: "program_name",
      },
      {
        text: "Book Category",
        align: "left",
        sortable: true,
        value: "bookCat",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Source/Vendor",
        align: "left",
        sortable: true,
        value: "vendor",
      },
      {
        text: "No of Copies",
        align: "left",
        sortable: true,
        value: "copies",
      },
      { text: "Rack Info Added?", value: "rackInfo", sortable: false },


    ],
    bookCategoryList: [],
    roleLink_list: [],
    role_list: [],
    editedIndex: -1,
    editedItem: {
      cno: "",
      libdept: "",
      acc: "",
      w_date: "",
      w_no: "",
      p_place: "",
      seriesId: "",
      program: "",
      vendor: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords: "",
      subject: "",
      place: "",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discount: 0,
      billNo: "",
      authorId: null,
      isDonated: "",
      language: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      isactive: false,
      id: "",
      authorText: false,
      publisherText: null,
      date_of_entry: null
    },
    publisherText: false,
    defaultItem: {
      seriesId: "",
      program: "",
      isbn: "",
      title: "",
      description: "",
      authors: null,
      edition: "",
      keywords: "",
      subject: "",
      place: "",
      volume: "",
      year: "",
      pages: null,
      copies: null,
      price: 0,
      bookprice: 0,
      discountPrice: 0,
      billNo: "",
      isDonated: "",
      authorId: null,
      purchaseDate: null,
      publicationDate: null,
      publicationYear: null,
      bookFormat: null,
      bookType: null,
      bookCat: null,
      publisher: null,
      library: null,
      language: null,
      isactive: false,
      id: "",
      date_of_entry: null
    },
    selectedtitle1: "",
    allBookInfo: [],
    titlevalue: "",
    book_authors: true,
    img: null,
    isImg: false,
    newbook_data: [],
    libdeptlist: [],
    rackData: [
      {
        rackName: "R1",
        blockName: "B1",
        category: "Comp",
        capacity: 5,
        available: 5,
      },
      {
        rackName: "R1",
        blockName: "B2",
        category: "Comp",
        capacity: 3,
        available: 3,
      },
      {
        rackName: "R1",
        blockName: "B3",
        category: "Comp",
        capacity: 2,
        available: 2,
      },
      {
        rackName: "R2",
        blockName: "B1",
        category: "mech",
        capacity: 5,
        available: 5,
      },
    ],
    bookCopies: 2,
    remaning: 2,
    publisherlist: [],
    departmentlist: [],
    autherlist: [],
    titlelist: [],
    isbnlist: [],
    serieslist: [],
    selectedpublisher: "ALL",
    selecteddepartment: "ALL",
    selectedauther: "ALL",
    selectedtitle: "ALL",
    selectedisbn: "ALL",
    selectedseries: "ALL",
    editcopydialog: true
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Book" : "Edit Book";
    },
  },

  mounted() {
    this.onLoad();
    this.editedItem.date_of_entry = new Date().toISOString().slice(0, 10)
  },
  watch: {
    dialog() {
      this.img = null;
      this.isImg = false;
      this.book_authors = false;
      // this.editedItem.isbn = ''
      this.publisherText = false;
    },
  },
  methods: {
    addpubliasher() {
      this.publishername = this.publishername.trim();
      this.publisher_short_name = this.publisher_short_name.trim();
      const data = {
        publisher_name: this.publishername,
        publisherShortName: this.publisher_short_name,
        publisherDisplayOrder: 1
      };
      if (this.publishername && this.publisher_short_name && this.publishername != "" && this.publisher_short_name != "") {
        axios
          .post("/admin/addPublisher", data)
          .then((res) => {
            this.publisherList = res.data.publisher_list;
            if (res.data.msg == "success") {
              this.$refs.form.reset();
              this.publisher_dialog = false;
              this.showSnackbar("green", "Publisher Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },
    settext() {
      this.editedItem.title = this.selectedtitle1.displayname;

    },

    onload1(value) {

      this.editedItem.title = value;
      this.titlevalue = value;


      if (value != "") {
        var params = { text: value, seaechbyacc: "title" };
        axios
          .post("/IssueBook/getsearchbytitleBookOnly", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.allBookInfo = res.data.allBookInfo;
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          })
          .finally(() => {
            // var overlay = false;
          });
      }
    },

    editcopy(item) {
      this.inSave = false;
      this.editcopydialog = true;
    },
    searchByA_no() {
      var params = {
        selecteddepartment: this.selecteddepartment,
        selectedpublisher: this.selectedpublisher,
        selectedseries: this.selectedseries,
        Acc_no: this.accno
      };

      this.overlay = true;
      axios
        .post("/Librarian/getBookDataWithfilter", params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.bookList = res.data.book_list;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    fetchBookInfo() {
      if (this.editedItem.isbn !== "") {
        axios
          .get(
            "https://cors-anywhere.herokuapp.com/https://www.googleapis.com/books/v1/volumes?q=" +
            this.editedItem.isbn
          )
          .then((res) => {
            this.editedItem.title = res.data.items[0].volumeInfo.title;
            this.editedItem.description =
              res.data.items[0].volumeInfo.description;
            this.editedItem.pages = res.data.items[0].volumeInfo.pageCount;
            this.editedItem.publicationDate =
              res.data.items[0].volumeInfo.publishedDate;
            let auth = res.data.items[0].volumeInfo.authors;
            if (auth.length > 0) {
              this.book_authors = true;
              for (let i = 0; i < auth.length; i++) {
                if (this.editedItem.authors == null) {
                  this.editedItem.authors = auth[i];
                } else {
                  this.editedItem.authors =
                    this.editedItem.authors + "," + auth[i];
                }
              }
              this.editedItem.authorText = true;
            } else {
              this.book_authors = false;
              this.editedItem.authorText = false;
            }

            if (res.data.items[0].volumeInfo.publisher !== null) {
              this.publisherText = true;
              this.editedItem.publisherText = this.publisherText;
              this.editedItem.publisherId =
                res.data.items[0].volumeInfo.publisher;
            } else {
              this.publisherText = false;
              this.editedItem.publisherText = this.publisherText;
            }
            this.img = res.data.items[0].volumeInfo.imageLinks.smallThumbnail;
            if (this.img) {
              this.isImg = true;
            } else {
              this.isImg = false;
            }
          });
      } else {
        this.editedItem = {};
      }
    },
    // cal_discount(pprice, price) {
    //   var cost = parseInt(pprice);
    //   var amt = parseInt(price);
    //   var discount = ((cost - amt) / cost) * 100;
    //   var per = discount.toFixed(0);
    //   this.editedItem.bookprice = per;
    // },
    cal_discount() {
      if (this.editedItem.discount == 0) {
        this.editedItem.bookprice = this.editedItem.price;
      } else {
        var discount = (this.editedItem.price * this.editedItem.discount) / 100;
        this.editedItem.bookprice = (this.editedItem.price - discount);
      }

    },
    addRack() {
      this.inSave = true;
      this.dialog = true;
      this.editedIndex = -1;
      this.$refs.form.reset();
    },
    editItem(item) {
      console.log("item---", item.isbn);
      this.inSave = false;
      this.editedIndex = this.bookList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedtitle1 = this.editedItem.title;
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        // alert(this.role_list[x].name+" : "+name)
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          // this.item.role = this.role_list[x].name
          break;
        }
      }
    },
    getavalableacc() {
      var params = { seriesid: this.editedItem.seriesId };
      this.overlay = true;
      axios.post("/Librarian/getnewacc_no_track", params).then((res) => {
        if (res.data.msg == "200") {
          this.editedItem.acc = res.data.acc_no;
          this.editedItem.isbn = res.data.isbn;
          this.overlay = false;
        } else {
          this.overlay = false;
        }
      });
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getBookData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            // this.bookCategoryList = res.data.bookCategory_list
            // this.roleLink_list = res.data.rack_list;
            // this.role_list = res.data.role_list;
            // this.org = res.data.org;
            this.program_list = res.data.programList;
            this.libdeptlist = res.data.librarydeptlist;
            this.bookList = res.data.book_list;
            this.bookTypeList = res.data.bookTypeList;
            this.bookFormatList = res.data.bookFormatList;
            this.bookCatList = res.data.bookCatList;
            this.languageList = res.data.languageList;
            this.publisherList = res.data.publisherList;
            this.libraryList = res.data.lib_list;
            this.authorList = res.data.authorList;
            this.bookSeriesList = res.data.bookSeriesList;

            this.publisherlist = res.data.publisherList;
            this.departmentlist = res.data.librarydeptlist;
            this.autherlist = res.data.authorList;

            this.serieslist = res.data.bookSeriesList;
            this.editedItem.library = res.data.libid.id;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", error); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // ==this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {

      if (this.editedItem.isbn == null || this.editedItem.isbn == "") {
        this.showSnackbar("#b71c1c", "Please Enter ESBN !!!");
      } else if (
        this.editedItem.copies == null ||
        this.editedItem.copies == ""
      ) {
        this.showSnackbar("#b71c1c", "Please Enter Number of Copies !!!");
      } else if (
        this.editedItem.library == null ||
        this.editedItem.library == ""
      ) {
        this.showSnackbar("#b71c1c", "Please Select Library !!!");
      } else {

        if (typeof this.selectedtitle1 === 'object')
          this.editedItem.title = this.selectedtitle1.displayname;
        else
          this.editedItem.title = this.selectedtitle1

        this.editedItem.copies = parseInt(this.editedItem.copies);
        this.editedItem.pages = parseInt(this.editedItem.pages);
        // this.editedItem.price = toString(this.editedItem.price)
        this.editedItem.purchaseDate = moment(
          String(this.editedItem.purchaseDate)
        ).format("DD-MM-YYYY");
        this.editedItem.publicationDate = moment(
          String(this.editedItem.publicationDate)
        ).format("DD-MM-YYYY");
        if (this.editedIndex > -1) {
          if (this.$refs.form.validate()) {
            axios
              .post("/Librarian/editBook", this.editedItem)
              .then((res) => {
                if (res.data.msg == "success") {
                  //window.alert(res.data.msg)
                  // Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                  this.showSnackbar("#4caf50", "Book Updated Successfully..."); // show snackbar on success
                  if (res.data.isbnExistMsg) {
                    this.showSnackbar("#b71c1c", res.data.isbnExistMsg); // show snackbar on error
                  }

                } else {
                  this.showSnackbar("#b71c1c", res.data.msg); // show snackbar on error
                }
                this.searchByA_no();
              })
              .catch((error) => {
                window.console.log(error);
                this.overlay = false;
              });
            this.close();
          }
        } else {
          this.overlay = true;
          axios
            .post("/Librarian/saveBook", this.editedItem)
            .then((res) => {
              if (res.data.msg == "success") {
                this.overlay = false;
                this.newbook_data = res.data.book_data;
                this.newBook = true;
                this.showSnackbar("#4caf50", "Book Added Successfully!!!"); // show snackbar on success

              } else if (res.data.status.code == "NA") {
                this.overlay = false;
                this.showSnackbar("#b71c1c", "Book Already Present!!!"); // show snackbar on error
              } else {
                this.overlay = false;
                this.showSnackbar("#b71c1c", res.data.msg);
              }
            })
            .catch((error) => {
              window.console.log(error);
              this.overlay = true;
            });
          this.close();
        }
      }
    }, //save()

    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    },

    addAuthor() {
      if (this.authorName && this.author_description) {
        this.authorName = this.authorName.trim();
        this.author_description = this.author_description.trim();
      }
      const data = {
        author_name: this.authorName,
        author_des: this.author_description,
      };
      if (this.authorName) {
        axios
          .post("/admin/addAuthor", data)
          .then((res) => {
            this.authorList = res.data.author_list;
            if (res.data.msg == "success") {
              this.$refs.form.reset();
              this.authordialog = false;
              this.showSnackbar("green", "Auther Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },
  },

};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}

.check {
  color: green;
  font-size: 20px;
  padding-right: 10px;
}

.cross {
  color: red;
  font-size: 20px;
  padding-right: 5px;
}

.col-sm-12.col-md-4.col-lg-4.col-12 {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>